import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import React, { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  CircularProgress,
} from "@mui/material";
// components
import Page from "src/components/Page";
import Label from "src/components/Label";
import Scrollbar from "src/components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
import { get_root_value } from "src/utils/domUtils";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "src/components/_dashboard/user";
//
// import USERLIST from "../_mocks_/user";
import DeletedModal from "src/components/modal/DeleteModal";
import { AllMemberListing, MemberListing } from "src/DAL/member/Member";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { baseUri, s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopover from "src/components/CustomPopover";
import { useContentSetting } from "src/Hooks/ContentSettingState";

import moment from "moment";
import CustomMUITable from "src/components/CustomMUITable/CustomMUITable";
import { convertCurrencyToSign } from "src/utils/constants";
import { get_commission_detail_api } from "src/DAL/commissionDetail/commissionDetail";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  //console.log(array, "arrays of data");
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    //console.log(query, "query ");
    return filter(
      array,
      (_user) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const USERLIST = [
  {
    id: 1,
    name: "Jhon",
    email: "jhon@gmail.com",
    date: "12-11-2022",
    time: "12:30 AM",
    credit: "$100",
    remaining: "$107",
    description: "lorem ipsum",
  },
  {
    id: 2,
    name: "Enola",
    email: "enola@gmail.com",
    date: "12-11-2022",
    time: "12:30 AM",
    credit: "$150",
    remaining: "$180",
    description: "lorem ipsum",
  },
  {
    id: 3,
    name: "Jhon",
    email: "jhon@gmail.com",
    date: "12-11-2022",
    time: "12:30 AM",
    credit: "$100",
    remaining: "$107",
    description: "lorem ipsum",
  },
  {
    id: 4,
    name: "Enola",
    email: "enola@gmail.com",
    date: "12-11-2022",
    time: "12:30 AM",
    credit: "$150",
    remaining: "$180",
    description: "lorem ipsum",
  },
];
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AffiliateTransactions({ type }) {
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const getMemberListing = async () => {
    setIsLoading(true);
    const result = await get_commission_detail_api(page, rowsPerPage, type);
    if (result.code === 200) {
      const data = result.transaction.map((transaction, index) => {
        return {
          ...transaction,
        };
      });
      setUserList(data);
      setTotalCount(result.total_member_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  useEffect(() => {
    getMemberListing();
  }, [page, rowsPerPage]);

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "user_name", label: "Name" },
    { id: "transaction_date", label: "Transaction Date" },
    {
      id: "amount",
      label: "Credit",
      renderData: (row) => {
        return (
          <>
            {type == "credit"
              ? "$" + row?.referral_commission.toFixed(2)
              : "$" + row?.amount.toFixed(2)}
          </>
        );
      },
    },
  ];
  const TABLE_HEAD1 = [
    { id: "number", label: "#", type: "number" },
    { id: "transaction_date", label: "Transaction Date" },
    {
      id: "amount",
      label: "Paid",
      renderData: (row) => {
        return (
          <>
            {type == "credit"
              ? "$" + row?.referral_commission.toFixed(2)
              : "$" + row?.amount.toFixed(2)}
          </>
        );
      },
    },
  ];

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="col-12">
        <CustomMUITable
          TABLE_HEAD={type == "credit" ? TABLE_HEAD : TABLE_HEAD1}
          data={userList}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </>
  );
}
