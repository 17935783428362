// material
import {
  Box,
  Grid,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useContentSetting } from "src/Hooks/ContentSettingState";
// components
import Page from "src/components/Page";
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates,
} from "src/components/_dashboard/app";
import AffiliateTransactions from "./AffiliateTransactions";

import { get_commission_detail_api } from "src/DAL/commissionDetail/commissionDetail";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import CommissionTabs from "./CommissionTabs";
import TotalCommission from "./TotalCommission";
import PaidCommission from "./PaidCommission";
import RemainingCommission from "./RemainingCommission";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AffiliateInformation() {
  const { userInfo, consultantInfo } = useContentSetting();
  const { commissionData, setCommissionData } = useState();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState();
  const commissionDetail = async () => {
    const result = await get_commission_detail_api(
      userInfo._id,
      page,
      rowsPerPage
    );
    if (result.code == 200) {
      console.log(result, "result for commission");
      setInputs(result);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    commissionDetail();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        {/* <div className="col-12 mb-5">
          <h2>Commission Detail</h2>
        </div> */}
        <div className="col-4">
          <TotalCommission
            title="Total Commission"
            count={inputs?.total_commission}
          />
        </div>
        <div className="col-4">
          <PaidCommission
            title="Paid Commission"
            count={inputs?.paid_commission}
          />
        </div>
        <div className="col-4">
          <RemainingCommission
            title="Pending Commission"
            count={inputs?.remaining_commission}
          />
        </div>
        <div className="col-12">
          <CommissionTabs />
        </div>
      </div>
    </div>
  );
}
