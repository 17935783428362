import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";

import { websiteUrl } from "src/config/config";
import CopyToClipboard from "react-copy-to-clipboard";

// import { AffliateLinksApi } from "src/DAL/90Day/NinetyDay";
import CustomMUITable from "src/components/CustomMUITable/CustomMUITable";
import { AffliateLinksApi } from "src/DAL/Groups/Groups";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AffliateLinks() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [templateData, setTemplateData] = useState([]);
  const [selectedObject, setSelectedObject] = useState({});
  const [openModel, setOpenModel] = useState(false);

  const [isAccess, setIsAccess] = useState();
  const [affiliateUrl, setAffiliateUrl] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("link_page_number", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };
  const getTemplatePagesListing = async () => {
    setIsLoading(true);
    const result = await AffliateLinksApi();
    if (result.code === 200) {
      setAffiliateUrl(result?.affiliate_url_name);
      let result_array = [];
      setIsAccess(result.is_access);

      result.links?.map((template) => {
        result_array.push({
          ...template,
          preview_link_data: {
            to:
              websiteUrl +
              template.sale_page_title_slug +
              "/" +
              result?.affiliate_url_name,
            target: "_blank",
            show_text: "Preview",
            className: "anchor-style",
          },
          MENU_OPTIONS: menuHandling(template),
        });
      });
      setTemplateData(result_array);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleCopiedUrl = () => {
    enqueueSnackbar("Preview Url copied to clipboard", { variant: "success" });
  };

  const handleCopiedAppointment = () => {
    enqueueSnackbar("Appointment Url copied to clipboard", {
      variant: "success",
    });
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "sale_page_title", label: "Page Title", alignRight: false },
    {
      id: "preview_link_data",
      label: "URL",
      type: "link",
      alignRight: false,
    },
    // {
    //   id: "action",
    //   label: "Action",
    //   alignRight: false,
    //   MENU_OPTIONS: "MENU_OPTIONS",
    //   type: "action",
    // },
  ];

  TABLE_HEAD.splice(2, 0, {
    id: "sale_page_title1",
    label: "Copy URL",
    alignRight: false,
    renderData: (row) => {
      return (
        <>
          {/* <CopyToClipboard
            text={websiteUrl + row.sale_page_title_slug + "/" + affiliateUrl}
            onCopy={() => handleCopiedUrl(true)}
          >
            <Tooltip title="Click to copy Preview URL">
              <Chip label="Copy Main URL" color="primary" variant="outlined" />
            </Tooltip>
          </CopyToClipboard> */}
          {/* <br></br> */}
          {row.type_of_page == "book_a_call_page" ? (
            <CopyToClipboard
              text={
                websiteUrl +
                row.sale_page_title_slug +
                "/appointment/" +
                affiliateUrl
              }
              onCopy={() => handleCopiedAppointment(true)}
            >
              <Tooltip title="Click to copy Appointment URL">
                <Chip
                  label="Copy Appointment URL"
                  color="primary"
                  variant="outlined"
                  className="mt-2"
                />
              </Tooltip>
            </CopyToClipboard>
          ) : (
            <CopyToClipboard
              text={websiteUrl + row.sale_page_title_slug + "/" + affiliateUrl}
              onCopy={() => handleCopiedUrl(true)}
            >
              <Tooltip title="Click to copy Preview URL">
                <Chip
                  label="Copy Main URL"
                  color="primary"
                  variant="outlined"
                />
              </Tooltip>
            </CopyToClipboard>
          )}
        </>
      );
    },
  });

  const handleEdit = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/website-pages/edit-page/${value.sale_page_title_slug}`, {
      state: value,
    });
  };

  const handleUpdatePageContent = (value) => {
    delete value.MENU_OPTIONS;
    navigate(
      `/website-pages/update-page-content/${value.sale_page_title_slug}`,
      {
        state: value,
      }
    );
  };

  const handleRedirect = (module, value) => {
    switch (module.module_actual_name) {
      case "testimonial":
        navigate(`/website-pages/${value._id}/testimonials`, {
          state: value,
        });
        break;
      case "payment_plans":
        navigate(`/website-pages/${value._id}/payment-plans`, {
          state: value,
        });
        break;
      case "website_programmes":
        navigate(`/website-pages/${value._id}/website-programmes`, {
          state: value,
        });
        break;
      case "buisness_strategy":
        navigate(`/website-pages/${value._id}/business-strategy`, {
          state: value,
        });
        break;
      case "website_faq":
        navigate(`/website-pages/${value._id}/website-faq`, {
          state: value,
        });
        break;
      case "wheel_of_life":
        navigate(`/website-pages/${value._id}/questions`, {
          state: value,
        });
        break;
      case "success_schedule":
        navigate(`/website-pages/${value._id}/success-schedule`, {
          state: value,
        });
        break;
      default:
      // code block
    }
  };

  const handleAgreeImportData = (value) => {
    setSelectedObject(value);
    setOpenModel(true);
  };

  const handleCopyURL = async (e) => {
    await navigator.clipboard.writeText(websiteUrl + e.sale_page_title_slug);
    enqueueSnackbar("Preview Url copied to clipboard", {
      variant: "success",
    });
  };
  const handleCopyAppointmentURL = async (e) => {
    await navigator.clipboard.writeText(
      websiteUrl + e.sale_page_title_slug + "/appointment"
    );
    enqueueSnackbar("Appointment URL Copied to clipboard", {
      variant: "success",
    });
  };
  const menuHandling = (value) => {
    const MENU_OPTIONS = [
      {
        label: "Copy main URL",
        icon: "eva:eye-fill",
        handleClick: handleCopyURL,
      },
    ];

    // if (value.type_of_page == "book_a_call_page") {
    //   MENU_OPTIONS.push({
    //     label: "Copy Appointment URL",
    //     icon: "eva:eye-fill",
    //     handleClick: handleCopyAppointmentURL,
    //   });
    // }

    // value?.module_info?.map((module) => {
    //   if (module.is_access) {
    //     MENU_OPTIONS.push({
    //       label: module.module_label_text,
    //       icon: "akar-icons:edit",
    //       handleClick: () => {
    //         handleRedirect(module, value);
    //       },
    //     });
    //   }
    // });
    return MENU_OPTIONS;
  };

  useEffect(() => {
    getTemplatePagesListing();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const handleNavigate = () => {
    navigate(`/website-pages/add-page`);
  };

  return (
    <>
      {/* <CustomConfirmation
        open={openModel}
        setOpen={setOpenModel}
        title_html={
          <div className="confirmation-popup-title">
            <h2>Are you sure you want to import template data?</h2>
            <p>
              Importing template data will update page content and copy other
              modules data.
            </p>
          </div>
        }
        handleAgree={handleDelete}
      /> */}
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-8 col-sm-12">
            <h2>Affiliate Links</h2>
          </div>
          {/* {isAccess == true ? (
            <div className="col-lg-4 col-sm-12 text-end">
              <button
                onClick={handleNavigate}
                className="small-contained-button"
              >
                Add New Page
              </button>
            </div>
          ) : (
            ""
          )} */}
        </div>

        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={templateData}
          className="card-with-background"
          // custom_pagination={{
          //   total_count: totalCount,
          //   rows_per_page: rowsPerPage,
          //   page: page,
          //   handleChangePage: handleChangePage,
          //   onRowsPerPageChange: handleChangeRowsPerPage,
          // }}
          // pageCount={pageCount}
          // totalPages={totalPages}
          localSearchName={"delegate_links"}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </>
  );
}
