import { invokeApi } from "../../bl_libs/invokeApi";

export const DeleteSelfImageApi = async (id) => {
  const requestObj = {
    path: `api/self_image/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const selfImageListing = async (data) => {
  const requestObj = {
    path: `api/self_image_question/consultant/?module_type=self_image`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const getDetailSelfImage = async (id) => {
  const requestObj = {
    path: `api/self_image_question/${id}?module_type=self_image`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DeleteQuestion = async (id) => {
  const requestObj = {
    path: `api/self_image_question/${id}?module_type=self_image`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddSelfImageApi = async (data) => {
  const requestObj = {
    path: `api/self_image_question/consutant`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditSelfImageApi = async (data, id) => {
  // console.log(id, "params.id");
  const requestObj = {
    path: `api/self_image_question/consultant/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
// export const getGoalStatementQuestion = async (id) => {
//   console.log(id, "params.id");
//   const requestObj = {
//     path: `api/member/get_goal_statement_by_member_id/${id}`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     // postData: data,
//   };
//   return invokeApi(requestObj);
// };
export const selfImageDetailApi = async (id) => {
  const requestObj = {
    path: `api/self_image_question/${id}?module_type=self_image`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const questionDetailApi = async (id) => {
  const requestObj = {
    path: `api/member/get_self_image_by_member_id/${id}?module_type=self_image`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteCommentApi = async (slug) => {
  const requestObj = {
    path: `api/self_image_reply/delete_reply/${slug}?module_type=self_image`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddKimsReplyApi = async (data) => {
  const requestObj = {
    path: `api/self_image_reply/add_reply`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
