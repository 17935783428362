import { invokeApi } from "../../bl_libs/invokeApi";

export const selfMemberListingApi = async (type) => {
  const requestObj = {
    path: `api/self_image_question/user_list_self_image_for_consultant?type=complete&module_type=journal_prompt`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const selfIncompleteMemberListingApi = async (type) => {
  const requestObj = {
    path: `api/self_image_question/user_list_self_image_for_consultant?type=incomplete&module_type=journal_prompt`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const selfSaveAndCloseListApi = async (type) => {
  const requestObj = {
    path: `api/self_image_question/user_list_self_image_save_and_close_for_consultant?module_type=journal_prompt`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const selfSaveAndCloseAddApi = async (data) => {
  const requestObj = {
    path: `api/self_image_question/self_image_save_and_close`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const selfIncompleteApi = async (data) => {
  const requestObj = {
    path: `api/self_image_question/self_image_incomplete`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const AddCommentOnQuestion = async (data) => {
  const requestObj = {
    path: `api/self_image_question/add_comment_on_self_image_question`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteQuestionCommentApi = async (id) => {
  const requestObj = {
    path: `api/self_image_question/comment/${id}?module_type=journal_prompt`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
export const questionReplyHistory = async (data) => {
  const requestObj = {
    path: `api/member/get_self_answer_stat`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
